const swarajCritiqueDB = [
    {
        id: 1,
        title: "Forward",
        description: "Mukanayak will stand strong with the encouragement and support of its subscribers, and I’m sure that it will show the right path of consequential work for the welfare of its own people. On this hopeful note, I would like to conclude my statement.",
        mdLink: 'forward',
        pbDate:'1920-01-31',

    },
    {
        id: 2,
        title: "Good Rule cannot be a substitute for Self-Rule (Swaraj)",
        description: "Looking at the world's geography from a political perspective, countries can be categorized into two groups: self-ruled and governed by foreign rule. Self-ruled countries are administered by their own people. However, the state of administration in countries with foreign rule is different.",
        mdLink: 'good-rule-cannot-be-substitue-for-self-rule',
        pbDate:'1920-02-28',
    },
    {
        id: 3,
        title: "Progenitors of Self-Rule (Swaraj)",
        description: "Much like Brahmadeva must have been proud of creating the universe, Vishwamitra must have also been proud of creating a parallel universe. Similarly, men also feel proud, thinking he is the originator of something. It is in accordance with this reasoning a disagreement has arisen among the proponents of India`s Swaraj over the claim of being its originator.",
        mdLink: 'progenitors-of-self-rule',
        pbDate:'1920-02-14',
    },
    {
        id: 4,
        title: "This is not Swaraj; this is a rule over us!",
        description: "In the previous issue, the ever-increasing greed of the Rashtriya Sabha - Congress was discussed. While it (the Congress) was born to transform British rule into good rule, over the period, instead of `Surajya` (good rule), it demanded and acquired the gift of `swaraj` (self-rule). And while this demand for Swaraj cannot be disrespected, it is important to see whether it is useful. How can a gift be harmful? There is no need to explain to those who know (the story) how the boon bestowed by the entranced Shankara to Bhasmasura caused trouble, even for Shankara himself.",
        mdLink: 'not-swaraj-but-rule-over-us',
        pbDate:'1920-01-28',
    },
    {
        id: 5,
        title: "The foes have robbed us of our saviour",
        description: "When we reflect on our experiences, it's common to notice that people who share similar struggles support each other. However, it is rare to see people from different countries standing in unity with those facing similar challenges in foreign lands.",
        mdLink: 'foes-robbed-us-of-our-saviour',
        pbDate:'1920-02-28',
    },
    {
        id: 6,
        title: "Our ascent in Swaraj (self-rule), Its extent and method",
        description: "The awaited Swaraj in India will not be an authoritarian or republican state (rule of all people) but a representative state. We have discussed thoroughly in the previous editorials how expanding voting rights and ensuring caste representation is essential to make this Swaraj a good rule.",
        mdLink: 'our-ascent-in-swaraj',
        pbDate:'1920-03-27',
    },
    {
        id: 7,
        title: "Parties in the Nation",
        description: "If anyone argues that he can accomplish a certain important social work all by himself, then not only would he fail but also be pitied by wise ones for his egotism and arrogant attitude.",
        mdLink: 'parties-in-the-nation',
        pbDate:'1920-04-10',

    },
    {
        id: 8,
        title: "Give proof of your affinity, or be ready to lose us",
        description: "A heart-rending incident of how brutally the touchable Hindus (high caste) treat the untouchables came to light recently. 'Balahayi' is the name of an untouchable caste that resides in Indore and Gwalior.",
        mdLink: 'give-proof-of-your-affinity',
        pbDate:'1927-07-01',
    },
    {
        id: 9,
        title: "Brahminism means caste supremacy established on the ignorance of the Bahujan community",
        description: "The manifestos released by the Bengal and Maharashtra branches of the Hindi Svatantrya Sangh (literal meaning, Organization for India`s Freedom, i.e. Indian National Congress ) have been published in this volume. If read from a comparative perspective, there is much to learn from these manifestos.",
        mdLink: 'brahminism-means-caste-supremacy',
        pbDate:'1928-11-16',

    },
    {
        id: 10,
        title: "Late. Shridhar Balwant Tilak",
        description: "On hearing the news of his death, Babasaheb was deeply moved and he shared his thoughts in an article which was published in 'Duniya' weekly magazine.",
        mdLink: 'late-shridhar-balwant-tilak',
        pbDate:'1928-06-02',
    },  
]

export { swarajCritiqueDB }
import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import Book from "./Book";
import { getServerBookUrl } from "../util/util";

const BookView = (props) => {
  const params = useParams();

  const bookUrl = getServerBookUrl(
    params.bookParent,
    params.language,
    params.bookName,
    params.pageType,
    params.pageNo
  );

  return (
    <Container>
      <Row>
        <Book bookUrl={bookUrl} />
      </Row>
    </Container>
  );
};

export default BookView;

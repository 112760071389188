import React, { useState } from "react";
import "./ProfilePageStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router";

import LogoutIcon from "@mui/icons-material/Logout";
import NotificationToggle from "../notificationToggle/NotificationToggle";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { userLogoutHandler } from "../../utilities/user-data/UserLogout";
import { auth } from "../../config/firebase";
import BetaTester from "../BetaTester/BetaTester";

function ProfilePage() {
  const user = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutLoading, setLogoutLoading] = useState(false);

  const handleLogout = async () => {
    setLogoutLoading(true);
    setTimeout(() => {
      userLogoutHandler({
        auth,
        dispatch,
      });
      setLogoutLoading(false);
      navigate("/");
    }, 1500);
  };

  return user.displayName  ? (
    <div className="profile-container">
      {logoutLoading && (
        <div className="overlay-style-loader">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div className="profileCard-container">
        <div className="profile-header">
          <Avatar
            className="avatar-pfp"
            alt="Remy Sharp"
            sx={{ width: 100, height: 100 }}
          >
            <span className="avatar-initial">
              {user.displayName
                .trim()
                .toUpperCase()
                .split(" ")
                .map((e) => e[0].toUpperCase()) 
                .join("")} 
                {/* do with style */}
            </span>
          </Avatar>
          <h3>{user.displayName}</h3>
          <p>@{user.username}</p>
        </div>
        <div className="profile-actions">
          <Button
            onClick={handleLogout}
            variant="outlined"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
          <div className="toggle--switches">
            <NotificationToggle />
            <BetaTester />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={"/"} />
  );
}

export default ProfilePage;

import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics, setUserId } from "firebase/analytics";
import { checkUploadFCMToken } from "../utilities/user-data/UserData";

import {
  initializeFirestore,
  persistentMultipleTabManager,
  tabManager,
  persistentLocalCache,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCt1krur7RzJ_5bMuqv0AUFLwGGNM_dOI4",
  authDomain: "baws-389800.firebaseapp.com",
  projectId: "baws-389800",
  storageBucket: "baws-389800.appspot.com",
  messagingSenderId: "644488033982",
  appId: "1:644488033982:web:23a3842a910f83d8d950ef",
  measurementId: "G-F638WGHNCT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics();
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ { tabManager: persistentMultipleTabManager() }
  ),
});

export const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;

// notification configs
export const messaging = getMessaging();

export const generateToken = async () => {
  const support = isSupported();
  if (support) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const notificationToken = await getToken(messaging, {
        vapidKey:
          "BNkNTJdM7j29aXLXLH9NqxjP4KIFsqXctf5sssl_N4wgwAGFBle0zeH1O4Ob91TQDcbQGd6mijMiu7kugrWX-rE",
      });
      const token = localStorage.getItem("notificationToken");

      if (token && token.length > 0) {
        if (token == notificationToken)
        { // same token was generated, no need to store and disturb things.
          return ;
        }
      }

      localStorage.setItem("notificationToken", notificationToken);
      checkUploadFCMToken();
    } else if (permission === "denied") {
      alert(
        "You have denied the permission to notifications,allow it in Browsers Settings"
      );
    }
  }
};

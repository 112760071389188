import React from 'react';

const Notes = () => {
    return (
        <div>
            This Note taking features in under development. Sorry, if you have reached here use browser refresh to go back!
        </div>
    );
};

export default Notes;

import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { bookHistory, key_bookUrl } from "../util/constants";
import {
  getUrlContext,
  initiate_interval_tree,
} from "../util/interval_tree.ts";
import { getServerBookUrlFromUrl } from "../util/util";
import { auth, db } from "../config/firebase";
import { Link, useNavigate } from "react-router-dom";
import { getUserBookMarks } from "../utilities/user-data/UserData";
import { deleteDoc, doc } from "firebase/firestore";
import { collection, query, where, onSnapshot, Timestamp } from "firebase/firestore";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function BookMarks(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [bookMarksArr, setBookMarksArr] = useState([]);
  const [listening, setListening] = useState(false);
  initiate_interval_tree();
  const user = useSelector((state) => state.userReducer);

  const handleClick = () => {
    setOpen(true);
  };
  function compareBookMarks( a, b ) {
    if ( a.created < b.created ){
      return 1;
    }
    if ( a.created > b.created ){
      return -1;
    }
    return 0;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  useEffect(() => {
    const getUserBookMarks = async () => {
      const uid = auth?.currentUser?.uid;
      if (!uid) return;

      const bookMarks = collection(db, `BookMarks/${uid}/collection`);
      const bookMarkQuery = query(bookMarks);

      try {
        const unsubscribe = onSnapshot(bookMarkQuery, (snapShot) => {
          const bookMarks = snapShot.docs.map((item) => {
            const obj = {
              url: item.data().url,
              text: item.data().text,
              pageNo: item.data().pageNo,
              context: item.data().context,
              baseUrl: item.data().baseUrl,
              id: item.id,
              created: item.data().created.toDate(),
            };
            return obj;
          });
          bookMarks.sort(compareBookMarks);
          setBookMarksArr(bookMarks);
        });

        setListening(true);
      } catch (err) {
        throw err;
      }
    };

    getUserBookMarks();
  }, [user.isLoggedIn]);

  function setBookContextUrl(e, url) {
    e.preventDefault();
    bookHistory.set(key_bookUrl, {
      shortUrl: e.target.href,
      longUrl: getServerBookUrlFromUrl(url),
    });

    navigate(new URL(url).pathname);
    // window.history.pushState("object or string", "Title", "/");
    // props.onChangeBookUrl(real_url);
  }

  const removeBookMark = async (id) => {
    try {
      await deleteDoc(
        doc(db, `BookMarks/${auth.currentUser.uid}/collection`, id)
      );
      handleClick();
      //setBookMarksArr(await getUserBookMarks(auth.currentUser.uid))
    } catch (err) {
      console.log("Eeror", err);
    }
  };

  const generateUrl = (url, page) =>
    `https://${url.replace(/\d+$/, "")}${page}`;

  const listItems = bookMarksArr.map((bookMarkObj) => {
    let path_url = `https://${bookMarkObj.url}`;
    let pageContext = getUrlContext(new URL(path_url).pathname) || [];

    return (
      <Card key={bookMarkObj.id} className="mb-3">
        <Card.Body>
          <Button
            className=""
            onClick={() => {
              removeBookMark(bookMarkObj.id);
            }}
            variant=""
            type="submit"
            style={{ float: "right" }}
          >
            <AiFillCloseCircle style={{ fontSize: "x-large" }} />
          </Button>
          <Card.Title>{bookMarkObj.title}</Card.Title>
          <Card.Text>{bookMarkObj.text}</Card.Text>
          <Card.Text>
            <a href={path_url} onClick={(e) => setBookContextUrl(e, path_url)}>
              On Page :{bookMarkObj.pageNo}
            </a>
          </Card.Text>
          <Breadcrumb>
            {pageContext.map(function (context_str, index) {
              let context = JSON.parse(context_str);
              let url = generateUrl(bookMarkObj.url, context.page_start);

              return (
                <Breadcrumb.Item
                  key={index}
                  href={url}
                  onClick={(e) => setBookContextUrl(e, url)}
                  style={{ fontSize: "0.75rem" }}
                >
                  {context.label}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Card.Body>
      </Card>
    );
  });

  if (!user.isLoggedIn) {
    return <Link to="/login">Login</Link>;
  }

  return (
    <>
      <ListGroup as="ol" numbered>
        {listItems}
      </ListGroup>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Bookark Deleted Successfully
        </Alert>
      </Snackbar>
    </>
  );
}
export default BookMarks;

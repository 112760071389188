import { useState, useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const WhiteSnackbar = styled(Snackbar)({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "white", // Set the background color to white
    color: "black", // Set the text color to black
  },
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function InstallSnackBar() {
  const user = useSelector((state) => state.userReducer);
  const [state, setState] = useState({
    open: false,
    Transition: SlideTransition,
  });
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(
    JSON.parse(localStorage.getItem("appInstalled") || false)
  );

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default behavior to keep the event for later use
      console.log("beforeinstallprompt event fired");
      event.preventDefault();
      setDeferredPrompt(event);
    };

    const handleAppInstalled = () => {
      setIsAppInstalled(() => {
        const appInstalled = true;
        localStorage.setItem("appInstalled", JSON.stringify(appInstalled));
        return appInstalled;
      });
    };

    // Check if the browser supports PWA features
    if ("serviceWorker" in navigator && "PushManager" in window) {
      // Attach the event listener for beforeinstallprompt
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      // Listen for the appinstalled event
      window.addEventListener("appinstalled", handleAppInstalled);
    }

    // Check if the app is already installed when the component mounts
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsAppInstalled(true);
    }

    return () => {
      // Remove the event listeners when the component is unmounted
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);
  useEffect(() => {
    // Delay showing the Snackbar by 5 minutes
    const timeout = setTimeout(() => {
      setState({
        ...state,
        open: true,
      });
    }, 300000);

    return () => clearTimeout(timeout);
  }, []);

  const handleInstallClick = async () => {
    try {
      console.log(deferredPrompt);
      if (deferredPrompt) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === "accepted") {
          setDeferredPrompt(null);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const action = (
    <>
      <Button size="small" variant="contained" onClick={handleInstallClick}>
        Add to home screen
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (isAppInstalled || !user.username) return null;

  return (
    <div>
      <WhiteSnackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="Love reading Babasaheb?"
        key={state.Transition.name}
        autoHideDuration={60000}
        action={action}
      />
    </div>
  );
}

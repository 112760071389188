import React, { useEffect, useId, useState } from "react";
import "./BetaTester.css";
import { auth, db } from "../../config/firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../store/user/userAction";

function BetaTester() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const [isBetaTester, setIsBetaTester] = useState(user.isBetaTester || false);

  useEffect(() => {
    const realTimeUpdate = () => {
      const userId = auth.currentUser.uid;
      const userCollection = collection(db, "User");
      const userQuery = query(userCollection, where("uid", "==", userId));

      const unsubscribeSnapshot = onSnapshot(userQuery, (querySnapshot) => {
        const userData = querySnapshot.docs[0].data();
        dispatch(userLogin(userData));
        setIsBetaTester(userData.isBetaTester);
      });

      return () => unsubscribeSnapshot();
    };

    realTimeUpdate();
  }, []);

  console.log(isBetaTester, "IS BETA TESTER");

  const updateUserDoc = async (uid, isBetaTester) => {
    try {
      const userCollection = collection(db, "User");
      const userQuery = query(userCollection, where("uid", "==", uid));

      const snapShot = await getDocs(userQuery);
      if (!snapShot.empty) {
        const userDoc = snapShot.docs[0];
        await updateDoc(userDoc.ref, {
          isBetaTester: isBetaTester,
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      console.log("Error updating user", error);
    }
  };
  const handleToggle = () => {
    const userId = auth.currentUser.uid;
    updateUserDoc(userId, !isBetaTester);
  };

  return (
    <div>
      <div className="tester-toggle">
        <h4 className="permit">Make me a Beta Tester: </h4>
        <div
          onClick={handleToggle}
          className={`toggle-switch ${isBetaTester === true ? "on" : "off"}`}
        >
          <div className="slider"></div>
        </div>
      </div>
    </div>
  );
}

export default BetaTester;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserId } from "firebase/analytics";

import { auth, analytics } from "./config/firebase";
import { userLogin, userLogout } from "./store/user/userAction";
import { getUserData, checkUploadFCMToken, checkUploadBookMarks } from "./utilities/user-data/UserData";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";




function App() {
  const dispatch = useDispatch();

  // const [uid, setUid] = useState("");
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;

        if (userId !== "" && user.emailVerified) {
          localStorage.setItem("uid", userId); // TODO: Check if this localStorage is important / used anywhere?
          const userData = await getUserData(userId);
          dispatch(userLogin(userData));
          setUserId(analytics, userId);
        }
      } else {
        // localStorage.removeItem("accessToken");
        dispatch(userLogout());
      }
    });

    return () => unsubscribe();
  }, []);

  //When user logs in for the first time and bookMarks are present in the localstorage
  useEffect(() => {
    const handleBookMarkToCloud = async () => {
      if (user.isLoggedIn) {
        if (localStorage.getItem("bookMarks")) {
          //take a backup copy in case we fail.  The user can contact us and we can tell them workaround.
          localStorage.setItem(
            "bookMarks_backup",
            localStorage.getItem("bookMarks")
          );
        }
        await checkUploadBookMarks();
      }
    };

    const handleFCMTokenToCloud = async () => {
      if (user.isLoggedIn) {
        if (localStorage.getItem("notificationToken")) {
          if (localStorage.getItem("FCMtokenUploadedToServer")) return;
          await checkUploadFCMToken();  
        }
      }
    };

    handleBookMarkToCloud();
    handleFCMTokenToCloud();
  }, [user.isLoggedIn]);

  useEffect(() => {
    const handleStorageChange = async (event) => {
      if (event.storageArea === localStorage) {
        await checkUploadBookMarks();
        console.log("localStorage changed:");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div>
      <RouterProvider router={router} />
      {/* <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/ffb2585b142d52a26dac4b61/script.js"></script> */}
    </div>
  );



}

export default App;

import Grid from "@mui/material/Grid";

import BookCoverPage from "../component/Books/BookCoverPage";

import "../styles/NewCatalog.css";
import { CardContent, Typography } from "@mui/material";

function NewCatalog(props) {
  if (props.catalogueData.length === 0) {
    return (
      <div>
        <CardContent>
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            Nothing to show here.
          </Typography>
        </CardContent>
      </div>
    );
  }

  return (
    <div>
      <h2 className="SortedCatalogue__title">Books</h2>
      <Grid container spacing={2}>
        {props.catalogueData.map((catalog) => {
          return (
            <Grid item lg={2} md={3} sm={4} xs={6} key={catalog.Path}>
              <BookCoverPage
                link={catalog.Path}
                imageSrc={"https://baws.in/assets/catalog/" + catalog.Filename}
                bookLabel={catalog.Label}
                endPage={catalog.EndPage}
                onToggle={props.onToggle}
                isReading={catalog.isReading}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default NewCatalog;

import React, { useEffect, use } from 'react';
import Badge from 'react-bootstrap/Badge';
import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import { getSuggestedQuery } from '@testing-library/react';
import suggestions from '../asset/suggestion.json';
import Fuse from 'fuse.js'

import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

var searchOnSelect = null;

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
  key: 'RECENT_SEARCH',
  limit: 6,
  transformSource({ source }) {
    return {
      ...source,
      onSelect(params) {
              console.log("onSelect", params)
              if (params.item.label) {
                params.setIsOpen(false)
                searchOnSelect(params.item.label);
                params.setQuery(params.item.label);
              }
      }
    }
  }
});

const options = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
     findAllMatches: true,
     minMatchCharLength: 3,
  // location: 0,
     threshold: 0.3,
  // distance: 100,
  // useExtendedSearch: false,
     ignoreLocation: true,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: [
    "label"
  ]
};

for (let i = 0; i<suggestions.length; i++) {
  
  suggestions[i]["label"] = '"'+suggestions[i]["label"].replace(/^[\d+\.\s\-\(\)]+/, '')+'"';

}
const fuse = new Fuse(suggestions, options);

function getUrl (item)
{
  return  window.location.protocol + "//" + window.location.hostname + "/books/baws/EN/" + item.vol_name + "/pdf/" + item.page_start;
}

function AutoComplete(props) {
  useEffect(() => {
    if (!props.fetched) {
      // props.fetchRules();
    }

    autocomplete({
      container: '#autocomplete',
      plugins: [recentSearchesPlugin],
      getSources() {
        return [
          {
            sourceId: 'label',
            getItems({ query }) {
              return fuse.search(query); 
            },
            getItemUrl({ item }) {
              return item.item.label;
            },
            getItemInputValue({ item, state }) {
              console.log(item, state);
              return item.item.label;
            },
            onSelect(params) {
              console.log("onSelect", params)
              if (params.item.item.label) {
                params.setIsOpen(false)
                props.onSelect(params.item.item.label);
                params.setQuery(params.item.item.label);
              }
            },
            onActive(params) {
              console.log("onActive", params)
            },
            templates: {
              // header() {
              //   return 'Suggestions';
              // },
              item({ item, html }) {
                return html `
                          <h6>
			  ${item.item.label}           <a style={{fontSize:'0.75em'}}  href=${getUrl(item.item)} onclick=${e => props.onSetBookUrl(e,  item.item.vol_name,item.item.page_start )} >Open Direct</a>  </h6><p>${item.item.vol_name} </p>
                        `;
              },
              // footer() {
              //   return 'Footer';
              // },
            },


          },

        ];
      },
      onStateChange({ state }) {
        searchOnSelect = props.onSelect;
	console.log(state);
      },
      onSubmit(params) {
        console.log("onSubmit", params);
        if (params.state.query) {
          props.onSelect(params.state.query);
        }
      },
      onReset(params) {
        console.log("onReset", params);
      },
      placeholder: "Type here to Search in BAWS",
      detachedMediaQuery: 'none',
      navigator: {
        navigate({ itemUrl }) {
          console.log(itemUrl);
        },
        navigateNewTab({ itemUrl }) {
          //   const windowReference = window.open(itemUrl, '_blank', 'noopener');

          //   if (windowReference) {
          //     windowReference.focus();
          //   }
        },
        navigateNewWindow({ itemUrl }) {
          //window.open(itemUrl, '_blank', 'noopener');
        },
      },
      initialState: {
        // This uses the `search` query parameter as the initial query
        query: props.defaultValue,
      },

    });
    console.log('mount it!');
  }, []);

  return (
    <div id="autocomplete"></div>

  );
  function trigger_valueSetEvent() {

  }
}

export default AutoComplete;

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { writeBatch, doc } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { auth } from "../config/firebase";
import { db } from "../config/firebase";
import { checkUserNameTaken } from "../utilities/user-data/UserData";
import { serverTimestamp } from "firebase/firestore";
import { Form, useNavigation, useActionData, redirect } from "react-router-dom";

import "./Signup.css";

export async function action({ request }) {
  const formData = await request.formData();
  const displayName = formData.get("displayName").trim();
  const username = formData.get("username").trim();
  const email = formData.get("email").trim();
  const phone = formData.get("phone").trim();
  const password = formData.get("password");
  const isChecked = formData.get("isChecked");

  if (!email || !password || !email || !username || !isChecked) {
    return { message: "Please fill out all required fields." };
  }

  try {
    let ref_id = "baws_bot";

    if (localStorage.referer_id) {
      ref_id = localStorage.referer_id;
    }

    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await updateProfile(user, { displayName });
    await sendEmailVerification(user);

    const createUser = async (username) => {
      const batch = writeBatch(db);
      const ref = doc(collection(db, "User"));

      batch.set(ref, {
        uid: user.uid,
        created: serverTimestamp(),
        isBetaTester: false,
        ref_id,
        displayName,
        username,
        email,
        phone,
      });

      const Index = collection(db, "Index");
      const indexRef = doc(Index, `User/username/${username}`);
      batch.set(indexRef, {
        value: ref.id,
      });

      await batch.commit();
    };

    await createUser(username);
    await signOut(auth);
    return redirect("/login");
  } catch (error) {
    return { message: error };
  }
}

function Signup() {
  const navigation = useNavigation();
  const errorMessage = useActionData();

  const [user, setUser] = useState({
    displayName: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    isChecked: false,
  });

  const { displayName, username, phone, email, password, isChecked } = user;

  const [status, setStatus] = useState({
    isError: false,
    error: "",
    isSuccess: false,
    isUserNameTaken: false,
    usernameRequestSent: false,
  });

  const { isSuccess, isUserNameTaken, usernameRequestSent } = status;

  const updateStatus = (updatedStatuses) => {
    setStatus((prevState) => {
      return {
        ...prevState,
        ...updatedStatuses,
      };
    });
  };

  useEffect(() => {
    const usernameChecker = async () => {
      const regex = /^[\w-]+$/;
      if (username.length < 4) {
        return;
      }
      var test = username.search(regex);
      if (test === -1) {
        console.log("Only alphanumeric and underscore allowed");
        return;
      }

      updateStatus({ usernameRequestSent: true });
      try {
        const docs = await checkUserNameTaken(username);

        if (docs.length > 0) {
          updateStatus({ isUserNameTaken: true });
        } else {
          updateStatus({ isUserNameTaken: false });
        }
      } catch (err) {
        console.log("Error", err);
        updateStatus({ isUserNameTaken: false });
      } finally {
        updateStatus({ usernameRequestSent: false });
      }
    };

    usernameChecker();
  }, [username]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    setUser((prevState) => {
      return {
        ...prevState,
        [name]: name === "isChecked" ? checked : value,
      };
    });
  };

  return (
    <div className="Signup">
      <div className="Signup__content">
        <h1>Jai Bhim! Join the community of BAWS Readers</h1>
        <Form className="Signup__content-form" method="post">
          {errorMessage?.message && (
            <Alert severity="error">{String(errorMessage?.message)}</Alert>
          )}
          {isSuccess && (
            <Alert severity="success">
              Please check your email (including spam folder) to complete
              Signup!.
            </Alert>
          )}
          <p>Sign up</p>
          <div className="Signup__form-input">
            <TextField
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              label="Display Name"
              variant="outlined"
              value={displayName}
              name="displayName"
            />
          </div>
          <div>
            <TextField
              error={
                (username.length > 0 && username.length < 4) || isUserNameTaken
              }
              helperText={
                username.length > 3 && !isUserNameTaken ? (
                  <span style={{ color: "green" }}>
                    <IconButton>
                      <DoneIcon color="success" fontSize="small" />
                    </IconButton>
                    Username is available
                  </span>
                ) : username.length > 0 && isUserNameTaken ? (
                  <span>
                    Username is not available. At least 4 letters and use only
                    letters- a-z and underscore
                  </span>
                ) : (
                  ""
                )
              }
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              label="Username (your unique id)"
              variant="outlined"
              value={username}
              name="username"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      {username.length > 0 && usernameRequestSent && (
                        <CircularProgress />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              name="email"
            />
          </div>
          <div>
            <TextField
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              label="Phone Number (optional)"
              variant="outlined"
              value={phone}
              name="phone"
            />
          </div>
          <div>
            <TextField
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              name="password"
            />
          </div>
          <div>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={isChecked}
                  name="isChecked"
                />
              }
              label="I agree to use common sense and maintain respectful behaviour"
              labelPlacement="end"
            />
          </div>
          <div className="Signup__form-btn">
            <button
              disabled={navigation.state === "submitting"}
              style={{ width: "100%" }}
            >
              {navigation.state === "submitting" ? "Signing up..." : "Sign up"}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Signup;

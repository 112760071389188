import "../../src/styles/Navbar.css";

import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Logo from "../asset/baws-logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shadow, setShadow] = useState(false);
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  const activeStyle = ({ isActive }) => ({
    color: isActive && "#0965d1",
    textDecoration: isActive && `underline `,
  });

  return (
    <nav className={`nav-main ${shadow ? "shadow" : ""}`}>
      <div className="nav-container">
        <div className="nav-logo">
          <Link to={""}>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className={`nav-menu ${isOpen ? "open" : ""}`}>
          <NavLink style={activeStyle} to="/">
            Home
          </NavLink>
          <NavLink style={activeStyle} to="/about">
            About us
          </NavLink>
        </div>
        <div className="btns">
          {user.isLoggedIn ? (
            <>
              <NavLink to="profile" className="login-btn">
                <button>{user.username}</button>
              </NavLink>
            </>
          ) : (
            <>
              <Link to="/login" className="login-btn">
                <button>Login</button>
              </Link>
              <Link to="/signup">
                <button className="signup-btn">Sign Up</button>
              </Link>
            </>
          )}
        </div>
        <div className="nav-toggle">
          {user.isLoggedIn && (
            <Link to="profile" className="login-btn">
              <button>{user.username}</button>
            </Link>
          )}
          <div>
            <div onClick={() => setIsOpen(!isOpen)}>
              {!isOpen ? (
                <i>
                  <FaBars />
                </i>
              ) : (
                <i>
                  <MdClose />
                </i>
              )}
            </div>
          </div>
        </div>
        <div className={`handburger__menu ${isOpen ? "open" : ""}`}>
          <NavLink onClick={()=>setIsOpen(!isOpen)} style={activeStyle} to="/">
            Home
          </NavLink>
          <NavLink onClick={()=>setIsOpen(!isOpen)} style={activeStyle} to="/about">
            About us
          </NavLink>
          {!user.isLoggedIn && (
            <>
              <Link onClick={()=>setIsOpen(!isOpen)} to="/login">Login</Link>
              <Link onClick={()=>setIsOpen(!isOpen)} to="/signup">Signup</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import { useNavigate, useLocation } from "react-router-dom";
import BahishkritBharatLogo from "../asset/BahishkrutBharatLogo.jpg";
import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { booksServerUrl } from "../util/constants";
import { Container, Row } from "react-bootstrap";

function BlankPage(props) {
  //TODO: Based on the URL select the document to insert instead of hardcoded URL below.
  const navigate = useNavigate();
  const location = useLocation();
  const [html, setHtml] = useState("Loading...");

  useEffect(() => {
    var url = `${new URL(booksServerUrl).origin}/assets${location.pathname}`;
    async function loadPage() {
      try {
        const res = await fetch(url);
        const text = await res.text();
        setHtml(text);
      } catch (e) {
        alert(e.message);
      }
    }

    loadPage();
  }, []);

  return (
    <div style={{marginTop:'8rem'}}>
      <Container>
        <Row className="">
          <div style={{ textAlign: "center" }}>
            <p>
              <div
                style={{
                  height: "auto",
                  width: "300px",
                  display: "inline-block",
                }}
              >
                <img src={BahishkritBharatLogo} alt="logo" width={"100%"} />
              </div>
            </p>
            <div
              className="mt-5 paraReading"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
            <div className="mt-5" id="" style={{ height: "50px" }}>
              {" "}
            </div>
            <Navbar bg="light" expand="false" fixed="bottom" className="mt-5">
              <Container fluid>
                <Navbar.Brand onClick={(e) => navigate(-1)}>
                  Back to BAWS.IN
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: "50px" }}
                    navbarScroll
                  ></Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default BlankPage;

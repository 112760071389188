import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./BookCoverPage.css";
import { useCurrentlyReading } from "../../hooks/useCurrentlyReading";
import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";

const BookCoverPage = (props) => {
  const { link, imageSrc, bookLabel, endPage, onToggle, isReading } = props;

  const { currentlyReading } = useCurrentlyReading();

  const [intersected, setIntersected] = useState(false);

  const ref = useRef(null);

  const user = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersected(entry.isIntersecting);
          const lazyImage = entry.target;
          lazyImage.src = imageSrc;
          observer.unobserve(lazyImage);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const currentlyReadingBook = currentlyReading.find((b) => b.link === link);

  const currentLink = () => {
    const inCurrentReading = currentlyReading.some(
      (book) => book.link === link
    );
    if (inCurrentReading) {
      const currentPage = currentlyReadingBook.pageNo;
      const linkParts = link.split("/");
      const linkPrefix = linkParts.slice(0, -1).join("/");
      return `${linkPrefix}/${currentPage}?status=reading`;
    } else return `${link}`;
  };

  const startPage = Number(link.split("/").slice(-1)[0]);

  const readingProgress = () => {
    if (currentlyReadingBook) {
      const rangeDifference =
        currentlyReadingBook.endPage - currentlyReadingBook.startPage;

      const upperDifference =
        currentlyReadingBook.endPage - currentlyReadingBook.pageNo;

      const progressPercent =
        ((rangeDifference - upperDifference) / rangeDifference) * 100;

      return Math.round(progressPercent);
    }
  };

  const handleBookClick = (e) => {
    e.preventDefault();
    navigate(`${currentLink()}`);
  };

  return (
    <div className="book__coverpage">
      <Link to={currentLink()} onClick={handleBookClick}>
        <Paper
          elevation={8}
          sx={{
            borderRadius: "10px",
          }}
        >
          <img alt={bookLabel} src={intersected ? imageSrc : ""} ref={ref} />

          <p>{bookLabel}</p>

          {user.username && (
            <div className="below--title--div">
              <label>
                <Switch
                  sx={{
                    transform: isBigScreen
                      ? "scale(0.8)"
                      : isTabletScreen
                      ? "scale(0.8)"
                      : isMobileScreen
                      ? "scale(0.7)"
                      : "scale(1)",
                    "& .PrivateSwitchBase-input .MuiSwitch-input": {
                      margin: "0px",
                      padding: "0px",
                      width: "auto",
                    },
                  }}
                  checked={isReading}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    onToggle({
                      link,
                      startPage,
                      pageNo: startPage,
                      endPage,
                      updatedAt: serverTimestamp(),
                    });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                Reading
              </label>
              <p>{currentlyReadingBook && `${readingProgress()}%`}</p>
            </div>
          )}
        </Paper>
      </Link>
    </div>
  );
};

export default BookCoverPage;

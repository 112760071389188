import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Sort.css";

const Sort = ({ defaultValue, handleSort }) => {
  const handleSortChange = (event) => {
    handleSort(event.target.value);
  };

  return (
    <div className="sort">
      <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
        <InputLabel id="demo-select-small-label">Sort</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={defaultValue}
          label="Sort"
          onChange={handleSortChange}
        >
          <MenuItem value="Default">Default</MenuItem>
          <MenuItem value="Volume">Volume</MenuItem>
          <MenuItem value="Year">Year</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Sort;

import React from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import { extraContent, extraContentOrder1, extraContentOrder2} from './AboutData'

const ExtraContent = (props) => {
  return (
    <div>
      <p className="extra-content">
        {extraContent}
        <br/>
        {extraContentOrder1}
        <br/>
        {extraContentOrder2}
        <br/>
        <a
          href
          onClick={() => {
            props.setReadMore(!props.readMore);
          }}
        >
          <button className="read_more">
            Read less
            <BsFillArrowUpCircleFill style={{ marginLeft: "10px" }} />
          </button>
        </a>
      </p>
    </div>
  )
}

export default ExtraContent

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { pdfjsUrl } from "../util/constants";
import { stem } from "../util/stemmer";
import dictionary from "../asset/en_dict.json";
import { getUrlContext, initiate_interval_tree } from "../util/interval_tree";
import { useCurrentlyReading } from "../hooks/useCurrentlyReading";
import { catalogueDataFull } from "./data";
import EndOfBookPopup from "../component/Books/EndOfBookPopup";

const googleTag = () => {
  try {
    if (typeof window !== "undefined") {
      window.gtag("event", "view_item", {
        event_category: "engagement",
        event_label: "url",
        value: document.location.href,
      });
    }
  } catch (err) {
    console.error(err);
  }
};


const findBook = (volume, page) => {
  const last = (arr) => arr[arr.length - 1]
  const startPageNo = (book) => Number(last(book.Path.split("/")));
  const volumeName = (book) => book.Path.split("/")[4];

  let filteredByVolume = catalogueDataFull.filter(
    (b) => volumeName(b) === volume
  );
  return filteredByVolume.find(
    (book) => {
      return page >= startPageNo(book) && page <= book.EndPage
    }
  );
};

export default function Book(props) {
  initiate_interval_tree();
  
  const { currentlyReading, setCurrentPage } = useCurrentlyReading();
  const [allowPageTurn, setAllowPageTurn] = useState(true);
  const [isTrackReading, setIsTrackReading] = useState(false);
  const confirmationShown = useRef(false);
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const host =
    "localhost" === window.location.hostname
      ? "http://localhost:8888/web/viewer.html"
      : pdfjsUrl;
  const src = `${host}?file=${props.bookUrl}`;

  useEffect(() => {
    const status = searchParams.get("status");
    
    if (status === "reading") {
      const bookName = params.bookName;
      const pageNo = Number(params.pageNo);
      const catalogueBook = findBook(bookName, pageNo);

      const currReads =
        JSON.parse(localStorage.getItem("currentlyReading")) || [];
      const book = currReads.find((e) => e.link === catalogueBook?.Path);

      if (book && book.pageNo !== pageNo && !confirmationShown.current) {
        confirmationShown.current = true;
        let trackPage = window.confirm(
          "You have enabled tracking pages on this book. Do you want to start tracking the current page?"
        );
        setIsTrackReading(trackPage);
      } else if (book && book.pageNo === pageNo) {
        setIsTrackReading(true);
      }
    }

    const handleMessage = (event) => {
      try {
        if (event.data && event.data.includes("action")) {
          let message = JSON.parse(event.data);

          if (!message) return;

          switch (message.action) {
            case "pageChanged": {
              const { bookParent, language, bookName, pageNo } = message.data;
              let to;
              const catalogueBook = findBook(bookName, pageNo);
              // TODO : don't show end book popup when going backwards
              if (isTrackReading) {
                setCurrentPage(catalogueBook, pageNo);
                if (catalogueBook && catalogueBook.EndPage === pageNo) {
                  setAllowPageTurn(false);
                  console.log("You have reached the end of the book");
                }
                to = `/books/${bookParent}/${language}/${bookName}/pdf/${pageNo}?status=reading`;
              } else {
                to = `/books/${bookParent}/${language}/${bookName}/pdf/${pageNo}`;
              }


              window.history.replaceState({}, "", to);
              googleTag();
              break;
            }

            case "loadSearchPage": {
              window.history.length > 2 ? navigate(-1) : navigate("/");
              break;
            }
            default:
              console.log(`unhandled action: ${message.action}`, message);
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
      return;
    };

    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage, false);
  }, [isTrackReading, params.pageNo]);

  useEffect(() => {
    const dict = dictionary;
    window.dictionary = function (term) {
      var stemmed = stem(term);
      if (dict) return dict[stemmed];
    };
  }, []);

  return (
    <main>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          textAlign: "center",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        className="mobile_pdf_render"
        onLoad={(e) => {
          window.top.postMessage(
            JSON.stringify({
              error: false,
              message: "Hello World",
            }),
            "*"
          );
        }}
      >
        {src && <iframe src={src} height="100%" width="100%"></iframe>}
      </div>
      {!allowPageTurn && <EndOfBookPopup />}
    </main>
  );
}

import React, { useState } from "react";
import "./NotificationToggleStyles.css";
import { generateToken, isSupported } from "../../config/firebase";

export default function NotificationToggle() {
  const [permissionStatus, setPermissionStatus] = useState(
    isSupported() ? Notification.permission : "unsupported"
  );

  async function handleToggle() {
    const browserSupport = isSupported();

    if (browserSupport) {
      if (Notification.permission === "default") {
        await generateToken();
      } else if (Notification.permission === "denied") {
        alert("Go to the Site setting and Allow for notification");
      }
      setPermissionStatus(Notification.permission);
    } else {
      setPermissionStatus("unsupported");
    }
  }

  return (
    <div>
      <div className="notification-toggle">
        <h4 className="permit">
          Notifications {permissionStatus === "granted" ? "On" : "Off"}:
        </h4>
        <div
          onClick={handleToggle}
          className={`toggle-switch ${
            permissionStatus === "granted" ? "on" : "off"
          }`}
        >
          <div className="slider"></div>
        </div>
      </div>
      {permissionStatus === "unsupported" && (
        <p style={{ color: "red ", fontSize: "0.8rem" }}>
          Notification for your device is not supported.
        </p>
      )}
    </div>
  );
}

import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import "./SignupDialog.css";

type SignUpDialogType = {
  showSignupPopup: boolean;
  setShowSignupPopup: (arg: boolean) => void;
  setIsSignupPopupOpen: (arg: boolean) => void;
};

function SignupLoginDialog({
  showSignupPopup,
  setIsSignupPopupOpen,
}: SignUpDialogType) {
  const [open, setOpen] = React.useState(showSignupPopup || false);
  const handleClose = () => {
    setOpen(false);

    if (setIsSignupPopupOpen) {
      setIsSignupPopupOpen(false);
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpen(true);
    }, 20000); // 1 minute in milliseconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Jai Bhim. Please consider signing up to enjoy all features.
      </DialogTitle>
      <div className="SignupLoginDialogContainer">
        <Link to="/signup">
          <Button variant="contained" onClick={handleClose}>
            Sign-up
          </Button>
        </Link>
        <Typography>Already have an account? </Typography>
        <Link to="/login">
          <Button color="info" onClick={handleClose}>
            Login
          </Button>
        </Link>
      </div>
    </Dialog>
  );
}

export default SignupLoginDialog;

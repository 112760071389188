import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { AiOutlineLinkedin, AiOutlineMail } from 'react-icons/ai'
import { BiRupee } from 'react-icons/bi'
import './People.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function SimpleDialog(props) {
  const { onClose, selectedValue, open, details, name } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} >
      <DialogTitle>More about me - {name}</DialogTitle>
      <div className="dialog-details">
        {details}
      </div>
      <Button variant="outlined" onClick={onClose}>Close</Button>
    </Dialog>
  );
}

const People = (props) => {

  const { person } = props

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const description = person.about.slice(0, 400) + "..."



  return (
    <div className="container" id={person.id} >
      <div className="person-content">
        <div className="image-div" style={{ backgroundImage: `url(${person.img})` }}></div>
        <div className="empty-div"></div>
        <div className="details">
          <div className="person-name">
            {person.name}
          </div>
          <div className="person-job">
            - {person.job}
          </div>
          <div className="person-description">
            {description}
          </div>
          <Button variant="outlined" onClick={handleClickOpen}>
            Read more
          </Button>
          <SimpleDialog
            open={open}
            onClose={handleClose}
            details={person.about + ' ' + person.why_joined}
            name={person.name}
          />

          <div className="contact">
            Contact -
            <div className="social">
              <a href={person.linkdin} className="person-link"><AiOutlineLinkedin /></a>
              <a href={"mailto:" + person.mail} className="person-link"><AiOutlineMail /></a>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 10000 }}
                overlay={
                  <Tooltip id={`tooltip-${person.mail}`}>
                    <strong>{person.upi}</strong>.
                  </Tooltip>
                }
              >
                <a href={"upi://pay?pa="+person.upi+";pn="+person.name+";cu=INR"} className="person-link"><BiRupee /></a>
              </OverlayTrigger>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default People

import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import './SwarajCritque/ArticleStyle.css'

import { useParams } from "react-router-dom";

function SwarajCritique() {

  const [post, setPost] = useState(" ");
  const [loading, setLoading] = useState(false);

  const {article } = useParams();
  console.log(useParams());

  useEffect(() => {
    const getArticle = async () => {
      setLoading(true);
      try {
        import(`./SwarajCritque/markdowns/${article}.md`).then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => setPost(res))
            .catch((err) => console.error(err));
        });
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    getArticle();
  }, []);
  return (
    <div className="article" style={{ textAlign: "justify" }} >{loading ? <div>Loading...</div> : <Markdown>{post}</Markdown>}</div>
  );
} 

export default SwarajCritique;

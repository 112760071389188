import { Layout } from "./layout/Layout";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { action as loginAction } from "./auth/Login";
import { action as signUpAction } from "./auth/Signup";

import BookView from "./book-view/BookView";
import Notes from "./book-view/Notes";
import Invoices from "./book-view/invoices";
import AboutUS from "./component/AboutUS";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import AuthAction from "./auth/AuthAction";
import HomeLayout from "./layout/HomeLayout";
import SearchPage from "./book-view/Search";
import BookMarks from "./component/BookMarks";
import Vows from "./component/Vows";
import BahishkritBharat from "./component/BahishkritBharat";
import Twitter from "./pages/Twitter";
import Books from "./pages/Books";
import Search from "./pages/Search";
import BlankPage from "./component/BlankPage";
import ProfilePage from "./component/Profile/ProfilePage";
import SwarajCritiqueArticles from "./pages/SwarajCritiqueArticles";
import SwarajCritique from "./pages/SwarajCritique";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Books />} />
          <Route path="search" element={<Search />} />
          <Route path="bookmarks" element={<BookMarks />} />
          <Route path="vows" element={<Vows />} />
          <Route
            path="catalogue"
            element={<BookView chosen_key={"catalogue"} />}
          />
          <Route path="bahishkrit_bharat" element={<BahishkritBharat />} />
          <Route path="swaraj-critique" element={<SwarajCritiqueArticles />} />
          <Route path="twitter" element={<Twitter />}></Route>
        </Route>
        <Route path="note" element={<Notes />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="about" element={<AboutUS />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="login" element={<Login />} action={loginAction} />
        <Route path="signup" element={<Signup />} action={signUpAction} />
        <Route path="auth/action" element={<AuthAction />} />
        <Route path="*" element={<BookView />} />
        {/* path="*" is for the error page, no? Also it should be outside of Routes parent wrapper */}
        <Route path="books">
          <Route
            path=":bookParent/:language/swaraj-critique/:date/:article"
            element={<SwarajCritique />}
          />
          <Route
            path=":bookParent/:language/bahishkrit-bharat/:date/:article"
            element={<BlankPage />}
          />
        </Route>
      </Route>
      <Route path="books">
        <Route
          path=":bookParent/:language/:bookName/:pageType/:pageNo"
          element={<BookView />}
        />
        <Route path=":bookParent/:language/:bookName" element={<BookView />} />
      </Route>
    </>
  )
);

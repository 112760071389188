const initialState = {
  username: "",
  displayName: "",
  isLoggedIn: false,
  isBetaTester: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        username: action.payload.username,
        displayName: action.payload.displayName,
        isLoggedIn: true,
        isBetaTester: action.payload.isBetaTester,
      };
    case "LOGOUT":
      return {
        username: "",
        displayName: "",
        isLoggedIn: false,
        isBetaTester: false,
      };
    default:
      return state;
  }
};

export default userReducer;

import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import religion from "../asset/religion.png";
import reservation from "../asset/reservation.png";
import gandhi from "../asset/gandhi.png";
import caste from "../asset/caste.png";
import servanas from "../asset/servanas.png";
import untouchability from "../asset/untouchability.png";
import buddhism from "../asset/Buddhism.jpg";
import constitution from "../asset/Constitution.jpg";
const PropularTopic = () => {
  const navigate = useNavigate();
  const searchHandler = (searchText) => navigate(`/search?q=${searchText}`);

  return (
    <>
      {" "}
      <div className="popular-topics">
        <h1 className="newCatalog__title">Popular Topics</h1>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={reservation} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("reservation")}
              >
                Reservation
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={servanas} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Brahmin Bania")}
              >
                Casteism
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={caste} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Caste")}
              >
                Caste
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img
                src={untouchability}
                alt=""
                width={"300px"}
                height={"150px"}
              />
              <p
                className="img__description"
                onClick={() => searchHandler("Untouchability")}
              >
                Untouchability
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={religion} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Religion")}
              >
                Religion
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={gandhi} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Gandhi Untouchables")}
              >
                Gandhi
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={buddhism} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Buddha Dhamma")}
              >
                Buddhism
              </p>
            </div>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="topics-img--wrap">
              <img src={constitution} alt="" width={"300px"} height={"150px"} />
              <p
                className="img__description"
                onClick={() => searchHandler("Constituent Assembly")}
              >
                Constitution
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PropularTopic;

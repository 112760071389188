import React, { useState, useEffect } from "react";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Bio from "./Bio";
import ExtraContent from "./ExtraContent";
import Button from "@mui/material/Button";
import ScrollToTopButton from "./ScrollToTopButton";
import { aboutUsInfo } from "./AboutData";

import "animate.css";
import "../styles/About.css";

function About() {
  const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();

  const linkName = !readMore && (
    <button className="read_more">
      Read More
      <BsFillArrowDownCircleFill style={{ marginLeft: "10px" }} />
    </button>
  );

  const routeHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.slice(1);
      const target = document.getElementById(hash);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div>
      <div className="about_container" id="top">
        <ScrollToTopButton />
        {/* <div className="home-btn"> */}
        {/*   <Button onClick={routeHandler} variant="outlined">Home</Button> */}
        {/* </div> */}
        <div className="wrapper">
          <div className="about-content">
            <h1 className="second_heading">Let&apos;s Read BabaSaheb</h1>

            <p className="aboutUS_info">
              {aboutUsInfo}
              <br />
              <a
                href
                className=""
                onClick={() => {
                  setReadMore(!readMore);
                }}
              >
                {linkName}
              </a>
              {readMore && (
                <ExtraContent setReadMore={setReadMore} readMore={readMore} />
              )}
            </p>
          </div>
        </div>
      </div>
      <Bio />
    </div>
  );
}

export default About;

import suggestions from '../asset/suggestion.json';
import  IntervalTree  from 'node-interval-tree';
import start_data from '../asset/start.json';



class Singleton {

  static instance: Singleton;
  private   _interval_tree_initiated ;
  private   _per_vol_tree;

  private constructor() {
    this._per_vol_tree = {};
    this._interval_tree_initiated = false;
      
  }

  public static getInstance(): Singleton {
      if (!Singleton.instance) {
          Singleton.instance = new Singleton();
      }
      return Singleton.instance;
  }

  public getIntervalTree() {
      return this._per_vol_tree;
  }
  public getTreeInitiated() {
    return this._interval_tree_initiated;
  }
}
var singleton = Singleton.getInstance();

var  interval_tree_initiated = singleton.getTreeInitiated();
var  per_vol_tree = singleton.getIntervalTree();

function book2pdf(book_page, vol_name){
  let ret_page = book_page + start_data[vol_name];
  switch (vol_name){
    case "Volume_10"    : if (book_page > 398) return ret_page+4; break;
    case "Volume_15"    : if (book_page > 704) return ret_page+2; break;
    case "Volume_17_02" : if (book_page > 300) return ret_page+2; break;
    default:              return ret_page; 
  }
  return ret_page;
}
export function initiate_interval_tree(){

  if (interval_tree_initiated) return;
  
  const intervalTree = new IntervalTree<string>()
  intervalTree.insert( 10,  15, "foo:bar"); // -> true
  intervalTree.insert(12,  12, "bar:foo" ); // -> true
  let result = intervalTree.search(11,11);
  console.log(intervalTree.search(11,1221)); // -> ['foo']

  for (let i = 0; i< suggestions.length; i++){
    let tree = null;
    if (! per_vol_tree[suggestions[i].vol_name]){
      //create interval tree and add to dict
      let createTree = new IntervalTree<string>();
      per_vol_tree[suggestions[i].vol_name] = createTree;
      tree = createTree;
    }
    else{
      tree = per_vol_tree[suggestions[i].vol_name];
    } 


    let to_insert = suggestions[i];
    let vol = suggestions[i].vol_name;
    to_insert.page_start = book2pdf(to_insert.page_start, vol);
    to_insert.page_end = book2pdf( to_insert.page_end, vol);
    tree.insert(to_insert.page_start, to_insert.page_end, JSON.stringify(to_insert));
  }
  
  interval_tree_initiated = true;
}
export function getUrlContext( url) {

  var splitURL = url.split("/");
  if (splitURL[2] != 'baws')
      return null;
  if (splitURL[3] != 'EN')
      return null;
  var container = splitURL[4];
  var page = parseInt(splitURL[6]);
  var to_return = per_vol_tree[container].search(page, page);
  return to_return;
}
export function getPageContext(item){
  
  let to_find = parseInt(item.actual_page.raw);
  let to_return = per_vol_tree[item.container.raw].search(to_find, to_find); 	
  return to_return;
}

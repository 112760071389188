export const catalogueDataFull = [
  {
    Path: "/books/baws/EN/Volume_01/pdf/20",
    Label: "Castes in India",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-20.jpg",
    Volume: 1,
    Year: 1916,
    Topics: "TBD1;TBD2;TBD3",
    EndPage: 37,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/38",
    Label: "Annihilation of Caste",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-38.jpg",
    Volume: 1,
    Year: 1936,
    Topics: "TBD1;TBD2;TBD4",
    EndPage: 111,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/114",
    Label: "Maharashtra as a Linguistic Province",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-114.jpg",
    Volume: 1,
    Year: 1948,
    Topics: "TBD1;TBD2;TBD5",
    EndPage: 143,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/144",
    Label: "Need for Checks and Balances",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-144.jpg",
    Volume: 1,
    Year: 1953,
    Topics: "TBD1;TBD2;TBD6",
    EndPage: 150,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/152",
    Label: "Thoughts on Linguistic States",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-152.jpg",
    Volume: 1,
    Year: 1955,
    Topics: "TBD1;TBD2;TBD7",
    EndPage: 216,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/220",
    Label: "Ranade, Gandhi and Jinnah,",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-220.jpg",
    Volume: 1,
    Year: 1943,
    Topics: "TBD1;TBD2;TBD8",
    EndPage: 255,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/258",
    Label: "Evidence before the Southborough Committee,",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-258.jpg",
    Volume: 1,
    Year: 1919,
    Topics: "TBD1;TBD2;TBD9",
    EndPage: 293,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/294",
    Label: "Federation versus Freedom",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-294.jpg",
    Volume: 1,
    Year: 1939,
    Topics: "TBD1;TBD2;TBD10",
    EndPage: 369,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/370",
    Label: "Communal Deadlock and a Way to solve It",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-370.jpg",
    Volume: 1,
    Year: 1945,
    Topics: "TBD1;TBD2;TBD11",
    EndPage: 395,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/396",
    Label: "States and Minorities",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-396.jpg",
    Volume: 1,
    Year: 1947,
    Topics: "TBD1;TBD2;TBD12",
    EndPage: 465,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/468",
    Label: "Small Holdings in India,",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-468.jpg",
    Volume: 1,
    Year: 1918,
    Topics: "TBD1;TBD2;TBD13",
    EndPage: 495,
  },
  {
    Path: "/books/baws/EN/Volume_01/pdf/496",
    Label: "Mr. Russell and the Reconstruction of Society",
    Filename: "baws.in-books-baws-EN-Volume_01-pdf-496.jpg",
    Volume: 1,
    Year: 1918,
    Topics: "TBD1;TBD2;TBD14",
    EndPage: 507,
  },
  {
    Path: "/books/baws/EN/Volume_02/pdf/18",
    Label: "In the Bombay Legislature",
    Filename: "baws.in-books-baws-EN-Volume_02-pdf-18.jpg",
    Volume: 2,
    Year: 1927,
    Topics: "TBD1;TBD2;TBD15",
    EndPage: 331,
  },
  {
    Path: "/books/baws/EN/Volume_02/pdf/332",
    Label: "Dr Ambedkar With the Simon Commission",
    Filename: "baws.in-books-baws-EN-Volume_02-pdf-332.jpg",
    Volume: 2,
    Year: 1928,
    Topics: "TBD1;TBD2;TBD16",
    EndPage: 519,
  },
  {
    Path: "/books/baws/EN/Volume_02/pdf/521",
    Label: "At the Round Table Conferences",
    Filename: "baws.in-books-baws-EN-Volume_02-pdf-521.jpg",
    Volume: 2,
    Year: 1930,
    Topics: "TBD1;TBD2;TBD17",
    EndPage: 813,
  },
  {
    Path: "/books/baws/EN/Volume_03/pdf/14",
    Label: "Philosophy of Hinduism",
    Filename: "baws.in-books-baws-EN-Volume_03-pdf-14.jpg",
    Volume: 3,
    Year: 1956,
    Topics: "TBD1;TBD2;TBD18",
    EndPage: 105,
  },
  {
    Path: "/books/baws/EN/Volume_03/pdf/106",
    Label: "India and Pre-Requisites of Communism",
    Filename: "baws.in-books-baws-EN-Volume_03-pdf-106.jpg",
    Volume: 3,
    Year: 1950,
    Topics: "TBD1;TBD2;TBD19",
    EndPage: 161,
  },
  {
    Path: "/books/baws/EN/Volume_03/pdf/162",
    Label: "Revolution and Counter-Revolution in Ancient India",
    Filename: "baws.in-books-baws-EN-Volume_03-pdf-162.jpg",
    Volume: 3,
    Year: 1956,
    Topics: "TBD1;TBD2;TBD20",
    EndPage: 450,
  },
  {
    Path: "/books/baws/EN/Volume_03/pdf/454",
    Label: "Buddha or Karl Marx",
    Filename: "baws.in-books-baws-EN-Volume_03-pdf-454.jpg",
    Volume: 3,
    Year: 1956,
    Topics: "TBD1;TBD2;TBD21",
    EndPage: 475,
  },
  {
    Path: "/books/baws/EN/Volume_04/pdf/12",
    Label: "Riddles in Hinduism",
    Filename: "baws.in-books-baws-EN-Volume_04-pdf-12.jpg",
    Volume: 4,
    Year: 1955,
    Topics: "TBD1;TBD2;TBD22",
    EndPage: 360,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/16",
    Label: "Untouchables or the Children of India's Ghetto",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-16.jpg",
    Volume: 5,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD23",
    EndPage: 49,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/50",
    Label: "Essays on Untouchables",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-50.jpg",
    Volume: 5,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD24",
    EndPage: 139,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/140",
    Label: "Essays on Untouchables- Social",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-140.jpg",
    Volume: 5,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD25",
    EndPage: 241,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/242",
    Label: "Essays on Untouchables- Political",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-242.jpg",
    Volume: 5,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD26",
    EndPage: 414,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/416",
    Label: "Essays on Untouchables- Religious",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-416.jpg",
    Volume: 5,
    Year: 1936,
    Topics: "TBD1;TBD2;TBD27",
    EndPage: 491,
  },
  {
    Path: "/books/baws/EN/Volume_06/pdf/18",
    Label: "Administration and Finance of The East India Company",
    Filename: "baws.in-books-baws-EN-Volume_06-pdf-18.jpg",
    Volume: 6,
    Year: 1915,
    Topics: "TBD1;TBD2;TBD28",
    EndPage: 63,
  },
  {
    Path: "/books/baws/EN/Volume_06/pdf/66",
    Label: "The Evolution of Provincial Finance in British India",
    Filename: "baws.in-books-baws-EN-Volume_06-pdf-66.jpg",
    Volume: 6,
    Year: 1925,
    Topics: "TBD1;TBD2;TBD29",
    EndPage: 329,
  },
  {
    Path: "/books/baws/EN/Volume_06/pdf/336",
    Label:
      "The Problem of the Rupee [History of Indian Currency and Banking, Vol. 1]",
    Filename: "baws.in-books-baws-EN-Volume_06-pdf-336.jpg",
    Volume: 6,
    Year: 1923,
    Topics: "TBD1;TBD2;TBD30",
    EndPage: 629,
  },
  {
    Path: "/books/baws/EN/Volume_06/pdf/636",
    Label: "Miscellaneous Essays.",
    Filename: "baws.in-books-baws-EN-Volume_06-pdf-636.jpg",
    Volume: 6,
    Year: 1926,
    Topics: "TBD1;TBD2;TBD31",
    EndPage: 697,
  },
  {
    Path: "/books/baws/EN/Volume_07/pdf/22",
    Label:
      "Who were the Shudras ? - How they came to be the Fourth Varna in the Indo-Aryan Society ?",
    Filename: "baws.in-books-baws-EN-Volume_07-pdf-22.jpg",
    Volume: 7,
    Year: 1946,
    Topics: "TBD1;TBD2;TBD32",
    EndPage: 235,
  },
  {
    Path: "/books/baws/EN/Volume_07/pdf/252",
    Label:
      "The Untouchables - Who were They and Why They Became Untouchables ?",
    Filename: "baws.in-books-baws-EN-Volume_07-pdf-252.jpg",
    Volume: 7,
    Year: 1948,
    Topics: "TBD1;TBD2;TBD33",
    EndPage: 398,
  },
  {
    Path: "/books/baws/EN/Volume_08/pdf/16",
    Label: "Reprint of Pakistan or the Partition of India",
    Filename: "baws.in-books-baws-EN-Volume_08-pdf-16.jpg",
    Volume: 8,
    Year: 1940,
    Topics: "TBD1;TBD2;TBD34",
    EndPage: 507,
  },
  {
    Path: "/books/baws/EN/Volume_09/pdf/16",
    Label: "What Congress and Gandhi have done to the Untouchables ?",
    Filename: "baws.in-books-baws-EN-Volume_09-pdf-16.jpg",
    Volume: 9,
    Year: 1945,
    Topics: "TBD1;TBD2;TBD35",
    EndPage: 416,
  },
  {
    Path: "/books/baws/EN/Volume_09/pdf/424",
    Label: "Mr. Gandhi and the Emancipation of the Untouchables",
    Filename: "baws.in-books-baws-EN-Volume_09-pdf-424.jpg",
    Volume: 9,
    Year: 1943,
    Topics: "TBD1;TBD2;TBD36",
    EndPage: 518,
  },
  {
    Path: "/books/baws/EN/Volume_10/pdf/20",
    Label:
      "Dr. Ambedkar as Member of the Governor-General's Executive Council (1942-46)",
    Filename: "baws.in-books-baws-EN-Volume_10-pdf-20.jpg",
    Volume: 10,
    Year: 1942,
    Topics: "TBD1;TBD2;TBD37",
    EndPage: 1093,
  },
  {
    Path: "/books/baws/EN/Volume_11/pdf/12",
    Label: "The Buddha and his Dhamma",
    Filename: "baws.in-books-baws-EN-Volume_11-pdf-12.jpg",
    Volume: 11,
    Year: 1957,
    Topics: "TBD1;TBD2;TBD38",
    EndPage: 618,
  },
  {
    Path: "/books/baws/EN/Volume_11/pdf/622",
    Label: "Vol. 11–SUPPLEMENT",
    Filename: "baws.in-books-baws-EN-Volume_11-pdf-622.jpg",
    Volume: 11,
    Year: 1957,
    Topics: "TBD1;TBD2;TBD39",
    EndPage: 631,
  },
  {
    Path: "/books/baws/EN/Volume_11/pdf/632",
    Label: "Pali and other Sources of the Buddha & his Dhamma with an Index",
    Filename: "baws.in-books-baws-EN-Volume_11-pdf-632.jpg",
    Volume: 11,
    Year: 1957,
    Topics: "TBD1;TBD2;TBD40",
    EndPage: 660,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/20",
    Label: "Ancient Indian Commerce",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-20.jpg",
    Volume: 12,
    Year: 1913,
    Topics: "TBD1;TBD2;TBD41",
    EndPage: 93,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/96",
    Label: "The Untouchables and the Pax Britannica",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-96.jpg",
    Volume: 12,
    Year: 1931,
    Topics: "TBD1;TBD2;TBD42",
    EndPage: 168,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/174",
    Label: "Lectures on the English Constitution",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-174.jpg",
    Volume: 12,
    Year: 1934,
    Topics: "TBD1;TBD2;TBD43",
    EndPage: 226,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/228",
    Label: "The Notes on Acts and Laws;",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-226.jpg",
    Volume: 12,
    Year: 1934,
    Topics: "TBD1;TBD2;TBD44",
    EndPage: 679,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/682",
    Label: "Waiting for a Visa",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-682.jpg",
    Volume: 12,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD45",
    EndPage: 712,
  },
  {
    Path: "/books/baws/EN/Volume_12/pdf/716",
    Label: "Other Miscellaneous Essays (eg: Manu and the Shudras)",
    Filename: "baws.in-books-baws-EN-Volume_12-pdf-716.jpg",
    Volume: 12,
    Year: 1932,
    Topics: "TBD1;TBD2;TBD46",
    EndPage: 780,
  },
  {
    Path: "/books/baws/EN/Volume_13/pdf/34",
    Label: "The Principal Architect of the Constitution of India",
    Filename: "baws.in-books-baws-EN-Volume_13-pdf-34.jpg",
    Volume: 13,
    Year: 1946,
    Topics: "TBD1;TBD2;TBD47",
    EndPage: 1263,
  },
  {
    Path: "/books/baws/EN/Volume_14_01/pdf/12",
    Label: "Dr. Ambedkar and the Hindu Code Bill",
    Filename: "baws.in-books-baws-EN-Volume_14_01-pdf-12.jpg",
    Volume: 14.1,
    Year: 1947,
    Topics: "TBD1;TBD2;TBD48",
    EndPage: 16,
  },
  {
    Path: "/books/baws/EN/Volume_14_01/pdf/18",
    Label:
      "Part One: General Discussion on the Draft (17th November 1947 to 14th December 1950)",
    Filename: "baws.in-books-baws-EN-Volume_14_01-pdf-18.jpg",
    Volume: 14.1,
    Year: 1947,
    Topics: "TBD1;TBD2;TBD49",
    EndPage: 801,
  },
  {
    Path: "/books/baws/EN/Volume_14_02/pdf/12",
    Label:
      "Hindu Code Bill Clause by Clause Discussion (5th February 1951 to 25th September 1951)",
    Filename: "baws.in-books-baws-EN-Volume_14_02-pdf-12.jpg",
    Volume: 14.2,
    Year: 1951,
    Topics: "TBD1;TBD2;TBD50",
    EndPage: 596,
  },
  {
    Path: "/books/baws/EN/Volume_15/pdf/18",
    Label: "Dr. Ambedkar as free India's first Law Minister",
    Filename: "baws.in-books-baws-EN-Volume_15-pdf-18.jpg",
    Volume: 15,
    Year: 1947,
    Topics: "TBD1;TBD2;TBD51",
    EndPage: 1118,
  },
  {
    Path: "/books/baws/EN/Volume_16/pdf/18",
    Label: "The Pali Dictionary",
    Filename: "baws.in-books-baws-EN-Volume_16-pdf-18.jpg",
    Volume: 16,
    Year: 1955,
    Topics: "TBD1;TBD2;TBD52",
    EndPage: 454,
  },
  {
    Path: "/books/baws/EN/Volume_16/pdf/456",
    Label: "Pali into English, Marathi, Hindi and Gujarathi",
    Filename: "baws.in-books-baws-EN-Volume_16-pdf-456.jpg",
    Volume: 16,
    Year: 1955,
    Topics: "TBD1;TBD2;TBD53",
    EndPage: 740,
  },
  {
    Path: "/books/baws/EN/Volume_16/pdf/742",
    Label: "Bouddha Pooja Path",
    Filename: "baws.in-books-baws-EN-Volume_16-pdf-742.jpg",
    Volume: 16,
    Year: 1956,
    Topics: "TBD1;TBD2;TBD54",
    EndPage: 762,
  },
  {
    Path: "/books/baws/EN/Volume_17_01/pdf/24",
    Label: "Mahad Satyagraha",
    Filename: "baws.in-books-baws-EN-Volume_17_01-pdf-24.jpg",
    Volume: 17.1,
    Year: 1927,
    Topics: "TBD1;TBD2;TBD55",
    EndPage: 73,
  },
  {
    Path: "/books/baws/EN/Volume_17_01/pdf/76",
    Label: "I have no Homeland",
    Filename: "baws.in-books-baws-EN-Volume_17_01-pdf-76.jpg",
    Volume: 17.1,
    Year: 1931,
    Topics: "TBD1;TBD2;TBD56",
    EndPage: 81,
  },
  {
    Path: "/books/baws/EN/Volume_17_01/pdf/86",
    Label: "Bringing the Untouchables on Political Horizon",
    Filename: "baws.in-books-baws-EN-Volume_17_01-pdf-86.jpg",
    Volume: 17.1,
    Year: 1927,
    Topics: "TBD1;TBD2;TBD57",
    EndPage: 203,
  },
  {
    Path: "/books/baws/EN/Volume_17_01/pdf/205",
    Label: "Temple Entry",
    Filename: "baws.in-books-baws-EN-Volume_17_01-pdf-205.jpg",
    Volume: 17.1,
    Year: 1930,
    Topics: "TBD1;TBD2;TBD58",
    EndPage: 232,
  },
  {
    Path: "/books/baws/EN/Volume_17_02/pdf/22",
    Label: "Socio-Political, Religious Activities",
    Filename: "baws.in-books-baws-EN-Volume_17_02-pdf-22.jpg",
    Volume: 17.2,
    Year: 1929,
    Topics: "TBD1;TBD2;TBD59",
    EndPage: 572,
  },
  {
    Path: "/books/baws/EN/Volume_17_03/pdf/30",
    Label: "Babasaheb's Speeches",
    Filename: "baws.in-books-baws-EN-Volume_17_03-pdf-30.jpg",
    Volume: 17.3,
    Year: 1927,
    Topics: "TBD1;TBD2;TBD60",
    EndPage: 605,
  },
  {
    Path: "/books/baws/EN/Volume_17_03/pdf/142",
    Label: "What Way Emancipation/What Path to Salvation",
    Filename: "baws.in-books-baws-EN-Volume_17_03-pdf-142.jpg",
    Volume: 17.3,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD61",
    EndPage: 176,
  },
  {
    Path: "/books/baws/EN/Volume_05/pdf/344",
    Label: "Poona Pact",
    Filename: "baws.in-books-baws-EN-Volume_05-pdf-344.jpg",
    Volume: 5,
    Year: 1935,
    Topics: "TBD1;TBD2;TBD62",
    EndPage: 410,
  },
  {
    Path: "/books/baws/EN/Volume_17_03/pdf/553",
    Label: "22 Vows Speech",
    Filename: "baws.in-books-baws-EN-Volume_17_03-pdf-551.jpg",
    Volume: 17.3,
    Year: 1956,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 577,
  },
  {
    Path: "/books/baws/MR/Khand_18_1/pdf/43",
    Label: "खंड १८ भाग १ १९२० ते १९३६",
    Filename: "baws.in-books-baws-MR-Khand_18_1-pdf-43.jpg",
    Volume: 18.1,
    Year: 1920,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 636,
  },
  {
    Path: "/books/baws/MR/Khand_18_2/pdf/39",
    Label: "खंड १८ भाग २ १९३७ ते १९४५",
    Filename: "baws.in-books-baws-MR-Khand_18_2-pdf-39.jpg",
    Volume: 18.2,
    Year: 1937,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 656,
  },
  {
    Path: "/books/baws/MR/Khand_18_3/pdf/39",
    Label: "खंड १८ भाग ३ १९४६ ते १९५६",
    Filename: "baws.in-books-baws-MR-Khand_18_3-pdf-39.jpg",
    Volume: 18.3,
    Year: 1946,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 620,
  },
  {
    Path: "/books/baws/MR/Khand_19/pdf/45",
    Label: "खंड १९ डॉ. बाबासाहेब आंबेडकर यांची पत्रकारिता १९२०-१९२८",
    Filename: "baws.in-books-baws-MR-Khand_19-pdf-45.jpg",
    Volume: 19,
    Year: 1920,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 685,
  },
  {
    Path: "/books/baws/MR/Khand_20/pdf/43",
    Label: "खंड २० डॉ. बाबासाहेब आंबेडकर यांची पत्रकारिता १९२९-१९५६",
    Filename: "baws.in-books-baws-MR-Khand_20-pdf-43.jpg",
    Volume: 20,
    Year: 1929,
    Topics: "TBD1;TBD2;TBD63",
    EndPage: 705,
  },
];

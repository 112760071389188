import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

const STORAGE_KEY = "currentlyReading";
export const useCurrentlyReading = () => {
  const [currentlyReading, setCurrentlyReading] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const postsRef = collection(
          db,
          `CurrentlyReadings/${user.uid}/collection`
        );
        const q = query(postsRef, where("uid", "==", user.uid));

        const unsubscribeSnapshot = onSnapshot(q, (querySnapshot) => {
          const currReadsFetched = querySnapshot.docs.map((doc) => doc.data());
          localStorage.setItem(STORAGE_KEY, JSON.stringify(currReadsFetched));
          setCurrentlyReading(currReadsFetched);
        });

        return () => unsubscribeSnapshot();
      } else {
        setCurrentlyReading([]);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const toggleBook = async (book) => {
    const user = auth.currentUser;

    if (!user) return;

    book = { ...book, uid: user.uid };
    const found = currentlyReading.find((b) => b.link === book.link);

    if (found) {
      const querySnapshot = await getDocs(
        query(
          collection(db, `CurrentlyReadings/${user.uid}/collection`),
          where("link", "==", book.link)
        )
      );

      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    } else {
      await addDoc(
        collection(db, `CurrentlyReadings/${user.uid}/collection`),
        book
      );
    }
  };

  const setCurrentPage = async (book, pageNo) => {
    const currUrl = window.location.href;

    const isReading = currUrl.includes("status=reading");
    const user = auth.currentUser;

    if (!user || !isReading) return;

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `CurrentlyReadings/${user.uid}/collection`),
          where("link", "==", book.Path)
        )
      );

      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, {
          pageNo: pageNo,
          updatedAt: serverTimestamp(),
        });
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return { currentlyReading, setCurrentPage, toggleBook };
};

import React, { useState, useEffect } from "react";
import { redirect, useNavigation, useActionData, Form } from "react-router-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../config/firebase";

import "./Login.css";

export async function action({ request }) {
  const formData = await request.formData();

  const email = formData.get("email");
  const password = formData.get("password");

  if (!email || !password) {
    return { error: "Please fill out all required fields." };
  }

  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    if (!user.emailVerified) {
      return { warning: "Email is not verified" };
    } else {
      return redirect("/");
    }
  } catch (err) {
    return { error: err.message };
  }
}

const Login = () => {
  const navigation = useNavigation();
  const errorMessage = useActionData();

  const loginWarning = errorMessage?.warning;
  const loginError = errorMessage?.error;

  const [user, setUser] = useState({
    email: "",
    password: "",
    recoverEmail: "",
    resendEmailAddress: "",
  });

  const { email, password, recoverEmail, resendEmailAddress } = user;

  const [loadingStates, setLoadingStates] = useState({
    loading: false,
    isLoading: false,
    resendEmailloading: false,
  });

  const { loading, resendEmailloading } = loadingStates;

  const [status, setStatus] = useState({
    isSuccess: false,
    isError: false,
    isWarning: false,
    warning: "",
    error: "",
    success: "",
    open: false,
    isResendEmailOpen: false,
  });

  const {
    isSuccess,
    isError,
    isWarning,
    warning,
    error,
    success,
    open,
    isResendEmailOpen,
  } = status;

  const updateUser = (updatedUserInfo) => {
    setUser((prevState) => {
      return {
        ...prevState,
        ...updatedUserInfo,
      };
    });
  };

  const updateStatus = (updatedStatuses) => {
    setStatus((prevState) => {
      return {
        ...prevState,
        ...updatedStatuses,
      };
    });
  };

  const updateLoadingState = (updatedSLoadingStatuses) => {
    setLoadingStates((prevState) => {
      return {
        ...prevState,
        ...updatedSLoadingStatuses,
      };
    });
  };

  const handleClickOpen = () => {
    updateStatus({
      isError: false,
      isSuccess: false,
      isWarning: false,
      open: true,
    });
    updateUser({ recoverEmail: "" });
  };

  const handleResendEmailOpen = () => {
    updateStatus({ isResendEmailOpen: true });
  };

  const handleResendEmailClose = () => {
    updateStatus({ isResendEmailOpen: false });
  };

  const handleClose = () => {
    updateStatus({ isOpen: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSendRecoveryEmail = async () => {
    updateStatus({ isError: false, isWarning: false, isSuccess: false });

    try {
      updateLoadingState({ loading: true });
      await sendPasswordResetEmail(auth, recoverEmail);

      updateStatus({
        isSuccess: true,
        success: "Password Recovery link is sent on you email.",
        open: false,
      });
    } catch (err) {
      updateStatus({
        isError: true,
        error: err,
        open: false,
      });
      console.log(err);
    } finally {
      updateLoadingState({ loading: false });
    }
  };

  const handleResendVerificationEmail = async () => {
    if (auth.currentUser.email === resendEmailAddress) {
      console.log("sending email to same user");
      try {
        updateLoadingState({ resendEmailloading: true });
        await sendEmailVerification(auth.currentUser);

        updateStatus({
          isSuccess: true,
          success: "Verification email sent.",
          isResendEmailOpen: false,
        });
      } catch (err) {
        updateStatus({
          isError: true,
          error: err,
          open: false,
          resendEmailAddress: false,
        });
      } finally {
        updateLoadingState({ resendEmailloading: false });
      }
    } else {
      console.log("sending email to different user");
    }
  };

  useEffect(() => {
    if (email && password) {
      updateLoadingState({ isLoading: true });
    }
  }, [email, password]);

  return (
    <>
      <div className="Login">
        <Form className="Login__content" method="post">
          {isSuccess && <Alert severity="success">{success}</Alert>}
          {(isError || loginError) && (
            <Alert severity="error">{error.message || loginError}</Alert>
          )}
          {(isWarning || loginWarning) && (
            <Alert severity="warning">{warning || loginWarning}</Alert>
          )}
          <p>Login</p>
          <div className="Login__content-form">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              name="email"
              onChange={handleChange}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              value={password}
              onChange={handleChange}
              name="password"
              type="password"
            />
            <div className="Login__content-input">
              <button
                disabled={navigation.state === "submitting"}
                type="submit"
                style={{ width: "100%" }}
              >
                {navigation.state === "submitting" ? "Logging in..." : "Login"}
              </button>
              <div
                className="Login__content-forgotpassword"
                onClick={handleClickOpen}
              >
                Forgot Password?
              </div>
              <div
                className="Login__content-forgotpassword"
                onClick={handleResendEmailOpen}
              >
                Didn't recieve the verification email?
              </div>
            </div>
          </div>
        </Form>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Recover Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the same email as used for signup.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={recoverEmail}
              name="recoverEmail"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              onClick={handleSendRecoveryEmail}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              Recover Password
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog open={isResendEmailOpen} onClose={handleResendEmailClose}>
          <DialogTitle>Resend verification email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the same email as used for signup.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={resendEmailAddress}
              name="resendEmailAddress"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResendEmailClose}>Cancel</Button>
            <LoadingButton
              onClick={handleResendVerificationEmail}
              endIcon={<SendIcon />}
              loading={resendEmailloading}
              loadingPosition="end"
              variant="contained"
            >
              Resend Verification Email
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Login;

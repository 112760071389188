import React, { Component } from "react";
import Spinner from "react-spinkit";
import { auth } from "../config/firebase";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import "./ResetPassword.css";

export default class ResetPassword extends Component {
  state = {
    email: null,
    error: "",
    password: "",
    success: false,
    validCode: null,
    verifiedCode: false,
  };

  componentDidMount() {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, this.props.actionCode).then(
      (email) => {
        this.setState({ email, validCode: true, verifiedCode: true });
      },
      (error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.setState({
          error: error.message,
          validCode: false,
          verifiedCode: true,
        });
      }
    );
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    const { actionCode } = this.props;
    const newPassword = this.state.password;

    // Save the new password.
    confirmPasswordReset(auth, actionCode, newPassword).then(
      () => {
        // Password reset has been confirmed and new password updated.
        this.setState({ success: true });
      },
      (error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        this.setState({ error: "Unknow error, please try again" });
      }
    );
  };

  setText = (evt) => {
    this.setState({ password: evt.target.value });
  };

  render() {
    const { email, error, password, success, validCode, verifiedCode } =
      this.state;

    let component;
    if (!verifiedCode) {
      component = <Spinner spinnerName="three-bounce" />;
    } else if (success) {
      component = (
        <div className="ResetPassword--div">
          <div className="ResetPassword">
            <h1>Password changed</h1>
            <p>You can now sign in with your new password</p>
          </div>
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div className="ResetPassword--div">
          <div className="ResetPassword">
            <h1>Reset your password</h1>
            <p>
              for <span>{email}</span>
            </p>

            <form onSubmit={this.handleResetPassword}>
              {error ? <div className="error">{error}</div> : ""}

              <input
                onChange={this.setText}
                value={password}
                type="password"
                placeholder="New password"
                required
              />
              <input type="submit" value="SAVE" className="button" />
            </form>
          </div>
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="ResetPassword--div">
          <div className="ResetPassword">
            <h1>Try resetting your password again</h1>
            <p className="error">Please ensure you clicked latest reset link</p>
          </div>
        </div>
      );
    }

    return component;
  }
}

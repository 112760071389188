import React, { useState } from "react";
import { AiFillLinkedin, AiOutlineMail } from "react-icons/ai";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import People from './People'
import { peopleInfo } from './AboutData'

import "../styles/About.css";

const Item = ({ item }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <People person={item} />
  );
};

const ItemList = ({ items }) => (
  <div>
    <h1
      style={{
        textAlign: "center",
        margin: "50px 0px 20px 0px",
        color: "#144272",
      }}
    >
      ✨Meet Our Team✨
    </h1>
    <div>
      {items.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </div>
  </div>
);

const App = () => {
  return <ItemList items={peopleInfo.sort(() => Math.random() - 0.5)} />;
};


export default App;

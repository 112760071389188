import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import groupBy from "lodash.groupby";
import { Col, Row } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Sort from "../component/Sort";
import SortedCatalogue from "../component/SortedCatalogue";
import PropularTopic from "../component/PropularTopics";
import SignupLoginDialog from "../component/SignupDialog";
import NewCatalog from "../element/NewCatalog";

import { catalogueDataFull } from "../book-view/data";
import { useCurrentlyReading } from "../hooks/useCurrentlyReading";
import { auth } from "../config/firebase";

import "./books.css";

export default function Books() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultFilterBy = searchParams.get("filterBy") || "English";
  const defalutSortBy = searchParams.get("sortBy") || "Default";
  const { currentlyReading, toggleBook } = useCurrentlyReading();
  const [isSignupPopupOpen, setIsSignupPopupOpen] = useState(false);
  const [filterByReading, setFilterByReading] = useState("allBooks");

  const handleFilter = (e) => {
    const value = e.target.value === "/EN/" ? "English" : "Marathi";
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentParams, filterBy: value });
  };

  const handleSort = (value) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentParams, sortBy: value });
  };

  const sortByVolume = (book) => `Volume ${book.Volume}`;

  const sortByYear = (book) => {
    if (book.Year >= 1910 && book.Year <= 1919) return "10s";
    if (book.Year >= 1920 && book.Year <= 1929) return "20s";
    if (book.Year >= 1930 && book.Year <= 1939) return "30s";
    if (book.Year >= 1940 && book.Year <= 1949) return "40s";
    if (book.Year >= 1950 && book.Year <= 1959) return "50s";
  };

  const langCode = defaultFilterBy === "English" ? "/EN/" : "/MR/";

  let catalogueData = catalogueDataFull.filter((item) =>
    item.Path.includes(langCode)
  );

  catalogueData = catalogueData.map((book) => {
    const isReading = currentlyReading.some((e) => e.link === book.Path);
    return { ...book, isReading };
  });

  catalogueData =
    filterByReading === "allBooks"
      ? catalogueData
      : catalogueData.filter((book) =>
          filterByReading === "reading" ? book.isReading : !book.isReading
        );

  let sortedData =
    defalutSortBy === "Default"
      ? catalogueData
      : defalutSortBy === "Year"
      ? groupBy(catalogueData, sortByYear)
      : groupBy(catalogueData, sortByVolume);

  const handleToggle = (book) => {
    const user = auth.currentUser;
    if (!user) setIsSignupPopupOpen(true);
    toggleBook(book);
  };

  const handleReadingFilter = (e) => {
    setFilterByReading(e.target.value);
  };

  return (
    <Row>
      <div className="filter-container">
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
          <InputLabel id="demo-select-small-label">Language</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={langCode}
            label="Language"
            onChange={handleFilter}
          >
            <MenuItem value="/EN/">English</MenuItem>
            <MenuItem value="/MR/">Marathi</MenuItem>
          </Select>
        </FormControl>

        <Sort defaultValue={defalutSortBy} handleSort={handleSort} />

        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
          <InputLabel id="demo-select-small-label">Status</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filterByReading}
            label="Status"
            onChange={handleReadingFilter}
          >
            <MenuItem value="allBooks">All Books</MenuItem>
            <MenuItem value="reading">Reading</MenuItem>
            <MenuItem value="notReading">Not Reading</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Col>
        {defalutSortBy !== "Default" ? (
          <SortedCatalogue
            sortedData={sortedData}
            sortedBy={defalutSortBy}
            onToggle={handleToggle}
          />
        ) : (
          <NewCatalog
            catalogueData={sortedData}
            handleSort={handleSort}
            onToggle={handleToggle}
          />
        )}
        <PropularTopic />
      </Col>
      {isSignupPopupOpen && (
        <SignupLoginDialog
          showSignupPopup={isSignupPopupOpen}
          setIsSignupPopupOpen={setIsSignupPopupOpen}
        />
      )}
    </Row>
  );
}
